/* eslint-disable react/no-danger */
/* eslint-disable react/forbid-prop-types */
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';

function SEO({ lang, meta, title, url, intl, description, path, script, scriptType }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            keywords
            siteUrl
            image
            square_image
            fb_app_id
          }
        }
      }
    `
  );

  const defaultTitle = intl.formatMessage({ id: 'blocto.meta.title' });
  const titleTemplate = intl.formatMessage({ id: 'blocto.meta.titleTemplate' });
  const mataDescription =
    description || intl.formatMessage({ id: 'blocto.meta.description' });
  const metaKeywords = site.siteMetadata.keywords;
  const metaUrl = url || site.siteMetadata.siteUrl;
  const metaImage = site.siteMetadata.image;
  const metaSquareImage = site.siteMetadata.square_image;
  const metaFbAppId = site.siteMetadata.fb_app_id;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defaultTitle={defaultTitle}
      title={title}
      titleTemplate={`%s | ${titleTemplate}`}
      link={[
        {
          rel: 'alternate',
          hrefLang: 'zh-Hant',
          href: `${metaUrl}/zh/${path}`,
        },
        {
          rel: 'alternate',
          hrefLang: 'en',
          href: `${metaUrl}/${path}`,
        },
        {
          rel: 'alternate',
          hrefLang: 'x-default',
          href: `${metaUrl}/${path}`,
        },
      ]}
      meta={[
        {
          name: 'description',
          content: mataDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:url',
          content: metaUrl,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: 'og:image:url',
          content: metaImage,
        },
        {
          property: 'og:image:width',
          content: '512',
        },
        {
          property: 'og:image:height',
          content: '512',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'fb:app_id',
          content: metaFbAppId,
        },
        {
          property: 'tangerine:image',
          content: metaSquareImage,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
      ].concat(meta)}
    >
      {
        (script && scriptType) && (
          <script type={scriptType} >
            {script}
          </script>
        )
      }
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  title: '',
  url: '',
  description: '',
  path: '',
  script: '',
  scriptType: ''
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
  intl: PropTypes.object.isRequired,
  script: PropTypes.string,
  scriptType: PropTypes.string
};

export default injectIntl(SEO);
